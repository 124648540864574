import { Helmet } from 'react-helmet-async';
import React, { useState } from 'react';
import axios from 'axios';

// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';

// sections
import {
  AppStoreList,
  AppServiceTrends,
  AppTimeOfDayServiceInsights,
  AppBrandLoyalty,
  AppVisitSpendTrends,
  AppTimeOfDayServiceConsistency,
  AppCustomerVisitFrequencyTrends,
} from '../sections/@dashboard/app';

// component for embedding QS iframe
/* const QSDashboard = ({embedUrl}) => (
  <iframe
      width="960"
      height="720"
      src={embedUrl}
      allowFullScreen
      title="QS Dashboard"
      style={{ width: '100%', height: '720px', marginTop: '20px' }}
  />
); */

// component for visual embedding
const QSVisual = ({embedUrl}) => (
<iframe
width="600"
height="400"
src={embedUrl}
allowFullScreen
title="QS Visual"
/>
);

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const theme = useTheme();

  const [storeID, setStoreID] = useState();
  const token = localStorage.getItem('token')
  if(token != null) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  const handleStoreSelection = (newStoreID) => {
    setStoreID(newStoreID);
  };

  //Quicksight embed url
  //const qsEmbedUrl = "https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/523612763411/dashboards/ece147f5-f148-4a3f-960d-a5dfc649e579?directory_alias=stephanstitchcode";
  const visEmbedurl = "https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/523612763411/dashboards/ece147f5-f148-4a3f-960d-a5dfc649e579/sheets/ece147f5-f148-4a3f-960d-a5dfc649e579_6d63ff8e-0387-49eb-8fea-45807d1dcbf9/visuals/ece147f5-f148-4a3f-960d-a5dfc649e579_179ce813-bff4-45f7-8643-09b0f024b30b?directory_alias=stephanstitchcode"
  
  return (
    <>
      <Helmet>
        <title> Dashboard | Uknomi Client Portal </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>



        <Grid container spacing={3}>

          <Grid item xs={6} md={6} lg={12}>
            <QSVisual embedUrl={visEmbedurl} />
          </Grid>
            
        </Grid>
      </Container>
    </>
  );
}

